import Vue from "vue";

const state = {
    "salesforceFields": {}
};

const mutations = {
    SET_SALESFORCE_FIELDS (currentState, data) {
        Vue.set(currentState, "salesforceFields", data);
    }
};

const actions = {
    async setSalesforceFields ({ commit }, data) {
        if (Object.hasOwn(data, "opportunity_default_days_add") && typeof data.opportunity_default_days_add !== "number") {
            // eslint-disable-next-line camelcase
            data.opportunity_default_days_add = parseInt(data.opportunity_default_days_add, 10);
        }
        if (data) {
            await commit("SET_SALESFORCE_FIELDS", data);
        }
    }
};

const getters = {
    "getSalesforceFields": (currentState) => currentState.salesforceFields
};

export default {
    "namespaced": true,
    state,
    actions,
    mutations,
    getters
};

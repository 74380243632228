<template>
    <v-dialog :value="dialog" max-width="1300" persistent>
        <v-card data-nw="fraudCheckDialogCard">
            <v-card-title>
                <v-col lg="10">
                    Sense Check
                </v-col>
                <v-col lg="2">
                    <v-btn class="primary--text float-right mt-3"
                           text
                           @click.stop="closeDialog"
                           data-nw="fraudCheckDialogClose"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-card-title>
                    Blacklist Check
                </v-card-title>
                <p id="blacklist_check_date" v-if="blacklistData.dateTime">
                    Blacklist check executed: {{blacklistData.dateTime}}
                </p>
                <v-data-table
                    id="blacklist_data_table"
                    :sort-desc="true"
                    :headers="blacklistHeaders"
                    :items="blacklistData.fieldMatchingBlacklist"
                    hide-default-footer
                >
                </v-data-table>

                <v-card-title>
                    Duplicate Check
                </v-card-title>
                <p v-if="duplicateData.dateTime">
                    Info fetched: {{duplicateData.dateTime}}
                </p>
                <v-data-table
                    id="duplicate_data_table"
                    :sort-desc="true"
                    :headers="duplicateHeaders"
                    :items="duplicateData.results"
                    hide-default-footer
                >
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    "props": {
        "dialog": {"default": false}
    },
    "methods": {
        closeDialog () {
            this.$emit(
                "update:dialog",
                false
            );
        },
        setData () {
            if (typeof this.fraudCheckReport !== "undefined" && this.fraudCheckReport !== null) {
                this.duplicateData = JSON.parse(this.fraudCheckReport).duplicate.data;
                this.blacklistData = JSON.parse(this.fraudCheckReport).blacklist.data;
            } else {
                this.duplicateData = {};
                this.blacklistData = {};
            }
        }
    },
    mounted () {
        this.setData();
    },
    "computed": {
        fraudCheckReport () {
            return this.$store.state.applicationFormValues.values.fraud_check_report;
        }
    },
    "watch": {
        fraudCheckReport () {
            this.setData();
        }
    },
    // eslint-disable-next-line max-lines-per-function
    "data" () {
        return {
            "duplicateData": {},
            "blacklistData": {},
            "blacklistHeaders": [
                {
                    "text": "Matched field",
                    "value": "field",
                    "sortable": false
                },
                {
                    "text": "Blacklisted word",
                    "value": "blackListedWord",
                    "sortable": false
                },
                {
                    "text": "Match on application value",
                    "value": "value",
                    "sortable": false
                }
            ],
            "duplicateHeaders": [
                {
                    "text": "OAF id",
                    "value": "oaf_id",
                    "sortable": false
                },
                {
                    "text": "Company",
                    "value": "company_name",
                    "sortable": false
                },
                {
                    "text": "Status",
                    "value": "status",
                    "sortable": false
                },
                {
                    "text": "Address",
                    "value": "address",
                    "sortable": false
                },
                {
                    "text": "Email",
                    "value": "email",
                    "sortable": false
                },
                {
                    "text": "Phone number",
                    "value": "phone_number",
                    "sortable": false
                },
                {
                    "text": "Contact",
                    "value": "contact",
                    "sortable": false
                }
            ]
        };
    }
};
</script>

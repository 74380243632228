<template>
    <div>
        <v-row>
            <v-col>
                <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :items="localTasks"
                    :disable-pagination="true"
                    :hide-default-footer="true"
                    item-key="id"
                    id="tasks_data_table">
                    <template v-slot:item.required="{item}">
                        <v-tooltip bottom nudge-left="20" content-class="custom-tooltip">
                            <template v-slot:activator="{ on }">
                                <v-checkbox
                                    v-on="on"
                                    :input-value="item.required"
                                    :disabled="isTaskEnabled(item.label, 'required')"
                                    off-icon="mdi-checkbox-blank-off-outline"
                                    @change="updateRequiredValue(item)"
                                />
                            </template>
                            <span>{{ getToolTipRequired(item) }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.completed="{item}">
                        <v-tooltip bottom nudge-left="20" content-class="custom-tooltip">
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                <v-checkbox
                                    :input-value="item.status"
                                    :disabled="true"
                                    off-icon="mdi-checkbox-blank-off-outline"
                                />
                                </div>
                            </template>
                            <span>{{ getToolTipStatus(item) }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.completedOverride="{item}">
                        <v-tooltip bottom nudge-left="20" content-class="custom-tooltip">
                            <template v-slot:activator="{ on }">
                                <v-checkbox
                                    v-on="on"
                                    :indeterminate="item.statusOverride === null"
                                    indeterminate-icon="mdi-checkbox-blank-outline"
                                    :input-value="item.statusOverride"
                                    :disabled="isTaskEnabled(item.label, 'override')"
                                    off-icon="mdi-checkbox-blank-off-outline"
                                    on-icon="mdi-checkbox-marked"
                                    @change="switchValue(item)">
                                </v-checkbox>
                            </template>
                            <span>{{ getToolTipStatusOverride(item) }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.screeningStatus="{item}">
                        <div v-if="getScreeningType(item) !== 'isNotScreening'">
                            <div v-if="getScreeningType(item) === 'isCompanyScreening'">
                                <v-icon @click.stop="verifiedScreeningStatusResponse()"
                                        :class="getCompanyColor()" class="screening-icon-font-size">
                                    mdi-information
                                </v-icon>
                            </div>
                            <div v-else-if="getScreeningType(item) === 'isPersonScreening'">
                                <v-icon @click.stop="verifiedScreeningStatusResponse()"
                                        :class="getOverallPersonsColor()" class="screening-icon-font-size">
                                    mdi-information
                                </v-icon>
                            </div>
                        </div>
                    </template>
                    <template v-slot:item.label="{item}">
                        {{ item.label }}
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <KYCScreeningStatusDialog
            :dialog.sync="dialog" :screeningHits="screeningHits()">
        </KYCScreeningStatusDialog>
    </div>
</template>

<style>
button.v-icon.screening-icon-font-size {
    font-size: 30px;
}

button.v-icon.screening-red-icon {
    color: #b22222;
}

button.v-icon.screening-green-icon {
    color: #008000;
}

button.v-icon.screening-yellow-icon {
    color: #daa520;
}

button.v-icon.screening-grey-icon {
    color: #9b9b9b;
}

.custom-tooltip {
    opacity: 1 !important;
    font-weight: bold;
}
</style>

<script>
import BaseElement from "@/components/baseComponents/BaseElement.vue";
import KYCScreeningStatusDialog from "@/components/views/dialogs/KYCScreeningStatusDialog.vue";
import _ from "lodash";
import cddColors from "@/components/form/elements/cddScreeningColors.js";
import gql from "graphql-tag";
import importedHeaders from "@/components/form/elements/headers/TaskListHeaders.json";

export default {
    "extends": BaseElement,
    "components": {
        KYCScreeningStatusDialog
    },
    "apollo": {
        "getTasks": {
            query () {
                return gql` {taskListOverview(formId: "${this.$route.params.id}"){
                                responseString
                                data{ id type required status statusOverride}
                }}`;
            },
            // eslint-disable-next-line max-lines-per-function
            update (data) {
                if (data.taskListOverview.data) {
                    const updatedDataWithLabel = [];
                    const sortOrder = {
                        "ContractSigned": 1,
                        "DocumentsProvided": 2,
                        "IdentificationProvided": 3,
                        "PersonaScreening": 4,
                        "CompanyIdAndScreeningCompleted": 5,
                        "CreditApproved": 6
                    };
                    // eslint-disable-next-line max-len
                    const sortedData = data.taskListOverview.data.sort((a, b) => (sortOrder[a.type] || 999) - (sortOrder[b.type] || 999));

                    // eslint-disable-next-line complexity
                    sortedData.forEach((task) => {
                        // eslint-disable-next-line default-case
                        switch (task.type) {
                        case "SalesforceAccountUpdated":
                            task.label = "SalesForce Account Updated";
                            break;
                        case "CreditApproved":
                            task.label = "Credit check";
                            break;
                        case "ContractSignedAt":
                            task.label = "Contract signed at";
                            break;
                        case "SenseCheckCompleted":
                            task.label = "GBS sense check completed";
                            break;
                        case "CompanyIdAndScreeningCompleted":
                            task.label = "Business screening";
                            break;
                        case "PersonaScreening":
                            task.label = "Persona screening";
                            break;
                        case "DocumentsProvided":
                            task.label = "Required documents provided";
                            break;
                        case "IdentificationProvided":
                            task.label = "Signee identification";
                            break;
                        case "ContractSigned":
                            task.label = "SEPA/contract signed";
                            break;
                        case "ApplicationEmailConfirmed":
                            task.label = "Application email confirmed";
                            break;
                        }
                        updatedDataWithLabel.push(task);
                    });
                    this.$store.dispatch(
                        "applicationFormValues/setTasks",
                        updatedDataWithLabel
                    );
                }
            },
            watchLoading (isLoading) {
                this.loading = isLoading;
            },
            skip () {
                return _.isNil(this.$route.params.id);
            }
        }
    },
    "methods": {
        switchValue (item) {
            if (item.statusOverride === 1) {
                item.statusOverride = 0;
            } else if (item.statusOverride === 0) {
                item.statusOverride = null;
            } else {
                item.statusOverride = 1;
            }
            const updateItem = {...item};
            this.$store.dispatch("applicationFormValues/updateTasks", updateItem);
            if (updateItem.type === "ContractSigned" && updateItem.statusOverride === true) {
                this.$store.dispatch("applicationFormValues/updateSepaMandateReceived", 1);
            }
        },
        isTaskEnabled (task, taskType) {
            const userRole = this.$store.state.userRole.userRole;
            const enabledTasksList = this.enabledTasksList;

            const roleTaskMapping = {
                "ROLE_CDD_USER": {
                    "override": !enabledTasksList.includes(task),
                    "required": true
                },
                "ROLE_GLOBAL_ADMIN": {
                    "override": false,
                    "required": false
                },
                "default": {
                    "override": enabledTasksList.includes(task),
                    "required": true
                }
            };
            const roleConfig = roleTaskMapping[userRole] || roleTaskMapping.default;

            return roleConfig[taskType];
        },
        getToolTipStatusOverride (item) {
            if (item.statusOverride) {
                return "Completed";
            } else if (item.statusOverride === null) {
                return "No override";
            } else if (!item.statusOverride) {
                return "Not completed";
            }
            return "";
        },
        getToolTipStatus (item) {
            if (item.status) {
                return "Completed";
            }
            return "Not completed";
        },
        getToolTipRequired (item) {
            if (item.required) {
                return "Required";
            }
            return "Not required";
        },
        updateRequiredValue (item) {
            const updateItem = {...item};
            updateItem.required = !item.required;
            this.$store.dispatch("applicationFormValues/updateTasks", updateItem);
        },
        assignPersons () {
            this.screeningPersons = [];
            for (const docPerson of this.$store.state.applicationFormValues.documentsPerson) {
                this.screeningPersons.push({
                    "name": `${docPerson.first_name} ${docPerson.last_name}`,
                    "cdd_color": docPerson.cdd_color,
                    "screening_progress": docPerson.screening_progress,
                    "risk_classification_status": docPerson.risk_classification_status,
                    "customer_vetting_status": docPerson.customer_vetting_status
                });
            }
        },
        assignCompany () {
            const currentCompany = this.$store.state.solarisBank.solarisBankBusiness;
            this.screeningCompany = {
                "name": this.$store.state.applicationFormValues.dnbSearchParams.company_name,
                "cdd_color": currentCompany.cdd_color ?? "",
                "screening_progress": currentCompany.screening_progress,
                "risk_classification_status": currentCompany.risk_classification_status,
                "customer_vetting_status": currentCompany.customer_vetting_status
            };
        },
        notAdminAlert () {
            this.$toast("Admin rights are required!", {"color": "error"});
        },
        screeningHits () {
            const screeningEntities = [];
            screeningEntities.push(this.screeningCompany);
            for (const person of this.screeningPersons) {
                screeningEntities.push(person);
            }
            return screeningEntities;
        },
        getScreeningType (task) {
            if (task.label === "Company ID & screening against sanction completed") {
                return "isCompanyScreening";
            } else if (task.label === "Persona screening against sanction completed") {
                return "isPersonScreening";
            }
            return "isNotScreening";
        },
        getCompanyColor () {
            if (this.screeningCompany.cdd_color === cddColors.redStatus) {
                return "screening-red-icon";
            } else if (this.screeningCompany.cdd_color === cddColors.yellowStatus) {
                return "screening-yellow-icon";
            } else if (this.screeningCompany.cdd_color === cddColors.greenStatus) {
                return "screening-green-icon";
            }
            return "screening-grey-icon";
        },
        getOverallPersonsColor () {
            let wasYellow = false;
            let wasGreen = false;
            for (const person of this.screeningPersons) {
                if (person.cdd_color === cddColors.redStatus) {
                    return "screening-red-icon";
                } else if (person.cdd_color === cddColors.yellowStatus) {
                    wasYellow = true;
                } else if (person.cdd_color === cddColors.greenStatus) {
                    wasGreen = true;
                }
            }
            if (wasYellow) {
                return "screening-yellow-icon";
            }
            if (wasGreen) {
                return "screening-green-icon";
            }
            return "screening-grey-icon";
        },
        verifiedScreeningStatusResponse () {
            if (this.userIsAdminOrGlobalAdmin) {
                this.dialog = true;
            } else {
                this.notAdminAlert();
            }
        }
    },
    mounted () {
        this.assignPersons();
        this.assignCompany();
        this.$root.$on("submitAssignedPersons", () => {
            this.assignPersons();
        });
        this.$root.$on("submitAssignedCompany", () => {
            this.assignCompany();
        });
    },
    "computed": {
        cddColors () {
            return cddColors;
        },
        tasks () {
            return this.$store.state.applicationFormValues.tasks;
        },
        userIsAdminOrGlobalAdmin () {
            return this.$store.state.userRole.userRole === "ROLE_ADMIN" ||
                this.$store.state.userRole.userRole === "ROLE_GLOBAL_ADMIN";
        }
    },
    "data" () {
        return {
            "screeningCompany": {},
            "screeningPersons": [],
            "dialog": false,
            "currentEdit": {},
            "loading": false,
            "headers": importedHeaders,
            "localTasks": [],
            "enabledTasksList": [
                "Signee identification",
                "Business screening",
                "Persona screening"
            ]
        };
    },
    "watch": {
        // JSON is used here to create a deep copy of the object
        tasks (newTasks) {
            this.localTasks = JSON.parse(JSON.stringify(newTasks));
        }
    },
    "props": ["lastUpdated"]
};
</script>

<template>
    <v-autocomplete
        v-model="model"
        :loading="isLoading"
        :search-input.sync="search"
        :items="items"
        item-text="description"
        item-value="index"
        :hide-no-data="isLoading"
        no-data-text="No results found"
        label="Search a Company"
        :data-nw="this.name"
        return-object
    ></v-autocomplete>
</template>

<script>
import apolloClient from "../../../ApolloClient";
import gql from "graphql-tag";

export default {
    "props": ["name"],
    "components": {},
    "data": () => ({
        "entries": [],
        "isLoading": false,
        "model": null,
        "search": null
    }),
    "computed": {
        country () {
            return this.$store.state.applicationFormValues.values.country;
        },
        items () {
            return this.entries.map((entry, index) => {
                const description = `${entry.name}  ${entry.registration_number}`;
                return {...entry, description, index};
            });
        }
    },
    "methods": {
        async getCompanies () {
            if (this.search?.length > 4 && this.entries.length === 0) {
                this.isLoading = true;
                const query = gql`query {
                solarisCompanySearch(countryCode: "${this.country}" searchString: "${this.search}"){
                responseString
                data{name registration_number registration_issuer
                }}}`;
                const result = await apolloClient.query({query});
                this.entries = result.data.solarisCompanySearch.data;
                this.isLoading = false;
            }
        }
    },
    "watch": {
        "$store.state.applicationFormValues.dialogSolaris" () {
            if (!this.$store.state.applicationFormValues.dialogSolaris) {
                this.entries = [];
                this.model = null;
            }
        },
        search () {
            this.getCompanies();
        },
        async model () {
            if (this.model !== null) {
                await this.$store.dispatch(
                    "applicationFormValues/setSolarisDialog",
                    {"params": this.model, "dialog": true}
                );
                if (this.$router.currentRoute.name === "CreateApplication") {
                    await this.$store.dispatch(
                        "applicationFormValues/solarisCompanyDetails",
                        this.model
                    );
                }
            }
        }
    }
};

</script>


import {individualRoleAmountValidator} from "@/components/validators/individual/individualRoleAmountValidator";

export function validateIndividual (data) {
    const individuals = data.$store.state.applicationFormValues.individuals;
    const roles = ["isUbo", "isLegalRepresentative", "isSignee"];

    for (const role of roles) {
        const validation = individualRoleAmountValidator(individuals, role, data.tableData);
        if (!validation.isValid) {
            data.$toast(validation.message, { "color": "error" });
            return;
        }
    }

    if (data.$refs.editIndividualDialog.validate()) {
        data.tableData.state = data.tableData.state ?? "new";
        const functionName = data.tableData.id ? "applicationFormValues/updateIndividual" : "applicationFormValues/addIndividual";
        data.$store.dispatch(functionName, data.tableData);
        data.$toast("The data has been added to the application, don't forget to save the application", { "color": "success" });
        data.closeDialog();
    } else {
        data.$toast("The data you submitted is invalid. Please check for incomplete fields.", { "color": "error" });
    }
}


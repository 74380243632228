<template>
    <v-app>
        <SideBar v-if="showSideBar" app></SideBar>
        <v-content >
            <router-view/>
        </v-content>
        <v-overlay v-if="this.$store.state.loader.loading" id="loader_overlay">
            <v-flex justify-center>
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-flex>
        </v-overlay>
        <ErrorDialog />
    </v-app>
</template>

<script>
import "intersection-observer";
import ErrorDialog from "./components/views/ErrorDialog";
import SideBar from "./components/uiElements/SideBar";

export default {
    data () {
        return {
            "showSideBar": true
        };
    },
    beforeUpdate () {
        this.showSideBar = this.$route.name !== "AuditLogApplication";
    },
    mounted () {
        this.showSideBar = this.$route.name !== "AuditLogApplication";
    },
    "name": "App",
    "components": {
        SideBar,
        ErrorDialog
    },
    "computed": {
        sessionLoaded () {
            return window.localStorage.getItem("jwt") !== null;
        }
    }
};
</script>

<style lang="scss">
    @import "./scss/app.scss";
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    .table-cursor tbody tr:hover {
        cursor: pointer;
    }
</style>

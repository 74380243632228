<template>
    <v-dialog :value="editIndividualDialog">
        <v-card height="100%">
            <v-card-title>
                <v-col lg="10">
                    {{ dialogText() }}
                </v-col>
                <v-col lg="2">
                    <v-btn class="primary--text float-right mt-3"
                           text
                           @click.stop="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-form ref="editIndividualDialog">
                    <v-list>
                        <v-list-item>
                            <v-row>
                                <v-col>
                                    <v-layout wrap>
                                        <v-flex v-for="(field, key) in tableHeaders"
                                                :key="key"
                                                :id="field.value+'_'+key"
                                                :class="'form_element field_type_'+field.type+' ' +
                                             'xs'+field.flex+' '+field.class">
                                            <v-text-field
                                                v-if="field.type === 'text'"
                                                v-bind="field"
                                                :id="field.id"
                                                :name="field.value"
                                                :value="tableData[field.value]"
                                                :label="field.text"
                                                :ref="field.value"
                                                @input="changeInput($event, field)"
                                                type="text"
                                                :rules="setRules(field.value)"
                                            />
                                            <v-text-field
                                                v-if="field.type === 'number'"
                                                v-bind="field"
                                                :id="field.id"
                                                :name="field.value"
                                                :value="tableData[field.value]"
                                                :label="field.text"
                                                :ref="field.value"
                                                @input="changeInput($event, field)"
                                                type="number"
                                                max="100"
                                                :rules="setRules(field.value)"
                                            />
                                            <template v-else-if="field.type === 'plain_text'">
                                                <div class="read-only-label-size">
                                                    <v-label>{{field.text}}</v-label>
                                                    <p :data-nw="field.value">{{ tableData[field.value] }}</p>
                                                </div>
                                            </template>
                                            <v-menu v-if="field.type === 'date'"
                                                    :id="field.id"
                                                    max-width="290"
                                                    :close-on-content-click="false"
                                                    v-model="showDatePicker">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        id="date-picker-value"
                                                        :value="formatDatePickerValue(tableData[field.value])"
                                                        v-on="on"
                                                        readonly
                                                        :label="field.text"
                                                        :rules="setRules(field.rules)"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    :value="formatDatePickerValue(tableData[field.value])"
                                                    :allowed-dates="disableFutureDates"
                                                    color="primary"
                                                    :ref="field.value"
                                                    :rules="setRules(field.value)"
                                                    @click:date="saveAndCloseDate($event)"
                                                />
                                            </v-menu>
                                            <v-autocomplete
                                                v-if="field.type === 'filterable_select'"
                                                max-width="290"
                                                :id="field.id"
                                                :label="field.text"
                                                :ref="field.value"
                                                :items="countries"
                                                @change="changeInput($event, field)"
                                                :data-nw="field.value"
                                                :rules="setRules(field.value)"
                                                :data-nw-value="tableData[field.value]"
                                                v-model="tableData[field.value]"
                                            ></v-autocomplete>
                                            <v-checkbox
                                                v-if="field.type === 'checkbox'"
                                                v-bind="field"
                                                :id="field.id"
                                                :ref="field.value"
                                                :label="field.text"
                                                v-model="tableData[field.value]"
                                                :rules="setRules(field.value)"
                                                :value="tableData[field.value]"
                                                :input-value="tableData[field.value]"
                                                @change="updateValue($event, field.value)"
                                            />
                                            <v-select
                                                v-if="field.type === 'select-type'"
                                                max-width="290"
                                                :id="field.id"
                                                :label="field.text"
                                                :ref="field.value"
                                                :items="booleanOptions"
                                                @change="changeInput($event, field)"
                                                :rules="setRules(field.value)"
                                                :data-nw="field.value"
                                                :data-nw-value="tableData[field.value]"
                                                v-model="tableData[field.value]"
                                            ></v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </v-list>
                </v-form>
            </v-card-text>
            <v-card-text>
                <v-row justify="end">
                    <v-btn class="text-capitalize mr-5"
                           right rounded color="primary"
                           @click="save">{{ persistButtonText() }}
                    </v-btn>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import BaseElement from "@/components/baseComponents/BaseElement.vue";
import {individualRulesValidator} from "@/components/validators/individual/individualRulesValidator";
import moment from "moment";
import {validateIndividual} from "@/components/validators/individual/individualValidator";


export default {
    "props": {
        "editIndividualDialog": {"default": false},
        "items": {"default": {}},
        "tableHeaders": {"default": {}},
        "isNew": {"default": null}
    },
    "extends": BaseElement,
    "data" () {
        return {
            "maxInputLength": 30,
            "close": false,
            "showDatePicker": false,
            "tableData": { ...this.items},
            "booleanOptions": [
                {"text": "No", "value": 0},
                {"text": "Yes", "value": 1}
            ]
        };
    },
    "watch": {
        editIndividualDialog (isOpen) {
            if (isOpen) {
                this.tableData = { ...this.items};
            }
        }
    },
    "computed": {
        countries () {
            return this.$store.state.countries.countries.map((country) => ({
                "value": country.id,
                "text": country.name
            }));
        }
    },
    "methods": {
        disableFutureDates (val) {
            return val <= new Date().toISOString().split("T")[0];
        },
        dialogText () {
            return this.isNew ? "Add Individual" : "Edit Individual";
        },
        persistButtonText () {
            return this.isNew ? "Add" : "Update";
        },
        formatDatePickerValue (date) {
            const formattedDate = moment(date).format("YYYY-MM-DD");
            return formattedDate === "Invalid date" ? "" : formattedDate;
        },
        setRules (field) {
            if (this.editIndividualDialog) {
                return individualRulesValidator(this, field);
            }

            return [];
        },
        save () {
            validateIndividual(this);
        },
        changeInput (value, field) {
            this.tableData[field.value] = value;
        },
        saveAndCloseDate (value) {
            this.showDatePicker = false;
            // eslint-disable-next-line camelcase
            this.tableData.birth_date = value;
        },
        updateValue (value, name) {
            this.tableData[name] = value;

            if (value === null) {
                this.tableData[name] = false;
            }
        },
        closeDialog () {
            this.$emit(
                "update:editIndividualDialog",
                false
            );
        }
    }
};
</script>

<style>
.read-only-label-size > label {
    font-size: 12px;
}
.read-only-label-size > p {
    font-size: 16px;
}
</style>

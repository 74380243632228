const roleConfig = {
    "isUbo": { "allowedAmount": 3, "roleText": "ubos" },
    "isLegalRepresentative": { "allowedAmount": 6, "roleText": "legal representatives" },
    "isSignee": { "allowedAmount": 1, "roleText": "signees" }
};

export function individualRoleAmountValidator (individuals, role, tableData) {
    const { allowedAmount, roleText } = roleConfig[role];
    const individualsWithRole = individuals.filter((individual) => individual[role]);

    const isEditingExisting = tableData.id && individualsWithRole.some((individual) => individual.id === tableData.id);
    const effectiveCount = isEditingExisting ? individualsWithRole.length - 1 : individualsWithRole.length;

    if (tableData[role] === true && effectiveCount >= allowedAmount) {
        return { "isValid": false, "message": `The allowed amount of ${roleText} are ${allowedAmount}` };
    }
    return { "isValid": true };
}

<template>
    <v-autocomplete
        v-bind="field"
        @input="updateStatusValue($event)"
        :value="currentStatus"
        :name="name"
        :label="field.label"
        :rules="setRules(field.rules)"
        :items="statuses"
        item-text="translation"
        item-value="status"
        :persistent-hint="true"
        :ref="this.name"
        :hint="getHint()"
        :disabled="!canEdit"
    />
</template>

<script>
import BaseElement from "../../baseComponents/BaseElement";
import gql from "graphql-tag";

export default {
    "extends": BaseElement,
    "components": {},
    "watch": {
        "$store.state.statusses.statusses" () {
            this.skipPossibleStatusesQuery = false;
        },
        "$store.state.applicationFormValues.values" () {
            this.skipPossibleStatusesQuery = false;
        }
    },
    "mounted" () {
        if (this.$store.state.statusses.statusses) {
            this.skipPossibleStatusesQuery = false;
        }
    },
    "apollo": {
        "possibleStatuses": {
            query () {
                const currentStatusName = this.$store.state.applicationFormValues.values.status;
                const allStatuses = this.$store.state.statusses.statusses;
                let currentStatus = null;
                if (allStatuses) {
                    currentStatus = this.$store.getters["statusses/getStatusByName"](currentStatusName);
                }
                let currentStatusId = -1;
                if (typeof currentStatus !== "undefined" && currentStatus !== null) {
                    currentStatusId = currentStatus.id;
                }
                this.currentStatus = {
                    "id": currentStatusId,
                    "status": currentStatusName,
                    "translation": currentStatus ? currentStatus.translation : currentStatusName,
                    "group_id": currentStatus ? currentStatus.group_id : -1
                };
                if (this.$store.state.userRole.userRole === "ROLE_GLOBAL_ADMIN") {
                    return gql`query {
                        possibleStatuses: status_relation (first: 10000)
                        {
                            data {
                                to_status { id status translation }
                            }
                        }
                    }`;
                }
                return gql`query {
                    possibleStatuses: status_relation (from_status_id: ${currentStatusId})
                    {
                        data {
                            to_status { id status translation }
                        }
                    }
                }`;
            },
            update (data) {
                const statuses = [];
                if (this.$store.state.userRole.userRole !== "ROLE_GLOBAL_ADMIN" &&
                    this.currentStatus.group_id === this.STATUS_BLOCKED_GROUP_ID) {
                    if (this.currentStatus.id !== -1) {
                        statuses.push(this.currentStatus);
                    }
                    this.statuses = statuses;
                } else {
                    // Push current status
                    if (this.currentStatus.id !== -1) {
                        statuses.push(this.currentStatus);
                    }
                    // Push all possible next statuses
                    data.possibleStatuses.data.forEach((status) => {
                        if (status.to_status !== null) {
                            const tempStatus = {
                                "id": status.to_status.id,
                                "status": status.to_status.status,
                                "translation": status.to_status.translation
                            };
                            statuses.push(tempStatus);
                        }
                    });


                    const allStatusses = this.$store.state.statusses.statusses;
                    if (allStatusses) {
                        // Push all closed statuses
                        allStatusses.forEach((status) => {
                            if (status.group_id === this.STATUS_CLOSED_GROUP_ID) {
                                const tempStatus = {
                                    "id": status.id,
                                    "status": status.status,
                                    "translation": status.translation
                                };
                                statuses.push(tempStatus);
                            }
                        });
                    }
                    this.statuses = statuses;
                    this.skipPossibleStatusesQuery = true;
                }
            },
            skip () {
                return this.skipPossibleStatusesQuery;
            },
            "fetchPolicy": "no-cache"
        }
    },
    "methods": {
        async updateStatusValue (updateValue, fieldName) {
            if (updateValue !== this.currentStatus.status) {
                await this.$store.dispatch("statusses/setCurrentStatusDirty");
            } else {
                await this.$store.dispatch("statusses/setCurrentStatusClean");
            }
            this.updateValue(updateValue, fieldName);
        }
    },
    data () {
        return {
            "STATUS_CLOSED_GROUP_ID": 11,
            "STATUS_BLOCKED_GROUP_ID": 19,
            "statuses": [],
            "skipPossibleStatusesQuery": true,
            "currentStatus": null
        };
    }
};
</script>
